<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName">
        <el-tab-pane label="荣誉记录" name="0">
          <Honor ref="honor" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Honor from './component/honorRecord'
export default {
  data() {
    return {
      activeName: "0",
    }
  },
  components: {
    Honor
  },
  mounted() {
	this.$refs.honor.getFirst()
  }
}
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>